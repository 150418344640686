@import 'variables';

.nf {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 40px;
  &__logo {
    text-align: center;
    width: 80px;
  }
  &__body {
    transform: translateY(-50px)
  }
  &__body-code {
    text-align: center;
    font-size: 25rem;
    font-weight: 600;
  }
  &__body-title {
    text-align: center;
    font-size: 50px;
    font-weight: 600;
  }
  &__body-links {
    margin-top: 63px;
  }
  &__body-link {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 15px;
    a {
      color: black;
      display: inline-block;
    }
  }
  &__footer {
    font-size: 1.8rem;
  }
}