@import 'variables';

.custom-button-wrap {
  display: flex;
  margin: 10px 0 15px;
  .custom-button {
    margin-right: 15px;
    margin-top: 0;
    margin-bottom: 0;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.custom-button {
  height: 60px;
  font-weight: 600;
  border-radius: 10px;
  text-align: center;
  color: #333;
  margin-top: 5px;
  margin-bottom: 15px;
  background: #ffcc2d;
  font-size: 1.8rem;
  padding: 10px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // margin: 0 10px 0 0;

  &:hover{
    opacity: 0.8;
  }

  &.small {
    width: 30%;
  }
  &.medium {
    width: 50%;
  }
  &.large {
    width: 100%;
  }

  &.low{
    padding: 10px 15px;
    margin-bottom: 0px;
    height: auto;
  }

  &.link{


    &.red{
      color: $dismiss-btn;
    }
  }


  &.loading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    }
  }
  &.green {
    background: $apply-btn;
    color: white;
  }
  &.red {
    background: $dismiss-btn;
    color: white;
  }

  &.gray{
    background: $gray-input;
    color: $font-color;
  }

  &.disabled {
      color: $disabledFont;
      background: $disabledBack;
  }
  &.link {
    display: inline;
    color: $adminBlue;
    text-decoration: underline;
    background-color: unset;
    padding: 0;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &.remove_link{
    color: $dismiss-btn;
    display: inline;
    text-decoration: underline;

    background: transparent;
    padding: 0;
    margin: 0;

    &:hover{
      color: $link-hover
    }

    &.disabled{
      color: $disabledFont;

      &:hover{
        cursor: default;
      }
    }
  }
}