@font-face {
  font-family: 'FiraSansCondensed';
  src:  url('../assets/fonts/FiraSansCondensed-ExtraBold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'FiraSansCondensed';
  src:  url('../assets/fonts/FiraSansCondensed-Bold.ttf') format('truetype');
  font-style: bold;
  font-weight: 600;
}
@font-face {
  font-family: 'FiraSansCondensed';
  src:  url('../assets/fonts/FiraSansCondensed-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'FiraSansCondensed';
  src:  url('../assets/fonts/FiraSansCondensed-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'FiraSansCondensed';
  src:  url('../assets/fonts/FiraSansCondensed-Thin.ttf') format('truetype');
  font-style: thin;
  font-weight: 300;
}