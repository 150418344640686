@import 'variables';

.dataSort{

    &__list{
        list-style: disc;
        margin: 0px;
        padding: 0px;
    }
    
    &__multitab{
        display: flex;
        flex-direction: column;

        &Item{
            display: flex;
            flex-direction: column;
            padding-bottom: 5px;
            margin-top: 5px;
            border-bottom: 1px dashed $uiGray;

            &:last-child{
                border-bottom: none;
            }
        }

        &Value{
            display: flex;
            flex-direction: column;
        }
    }
}