@import 'variables';

.collapse-rate {
  padding: 20px 0 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;

  &:last-child{
    border-bottom: none;
  }
}
.collapse-rate-group {
  margin: 8px 0;
  display: flex;

  &-head {
    display: flex;
    border-bottom: 2px dashed #c6c6c6;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 1.8rem;
    font-weight: 600;
    div {
      width: 50%;
      font-style: italic;
    }
  }
  &-title {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 8px;
  }
  &-info {
    width: 50%;
    padding: 5px 10px;
  }
  &-apply {
    width: 50%;
    padding: 5px 10px;
  }
  &-text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.3;
    padding-right: 10px;
  }
  &-solution {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 8px;
  }
  &-stars {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    &-wrap {
      width: 100%;
      display: flex;
      margin-right: 4px;
      img {
        height: 45px;
        width: 45px;
        margin-right: 4px;
      }
    }
  }
  &-rate {
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1.6rem;
    margin-left: 10px;
  }
  &-declarer {
    font-size: 1.6rem;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0px;
      border-bottom: none;
    }
    &-title {
      font-weight: bold;
      margin-bottom: 10px;
    }
    &-text {
      padding-bottom: 10px;
    }
  }
}