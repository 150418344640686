.error{

    &_title{
        font-size: 3rem;
    }

    &_message{
        font-size: 2rem;
    }


    &__soup{
        width: 180px;
        height: 180px;

        background: #f7ad3b;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &-body{
            animation: rotate_soup 8s infinite;
            animation-timing-function: linear;
        }
    }
}

@keyframes rotate_soup{
    0%{
        transform: rotate(0deg) scale(1);
    }
    100%{
        transform: rotate(360deg) scale(1);
    }
}