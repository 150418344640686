@import 'variables';

.wrap.login{
  height: 100vh;
  min-height: 675px;
  position: relative;
  padding: 50px 10px 15px;
  display: flex;
  flex-direction: column;
  background: $c-lightgray;
  align-items: center;
  box-sizing: border-box;
}
.login-logo{
  height: 45px;
  margin-bottom: 25px;
  img {
    height: 100%;
  }
}
.login-wrapper{
  width: 550px;
  background: white;
  border-radius: 22px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.login-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
}
.login-link{
  width: 50%;
  padding: 17px 0 17px;
  border-bottom: 3px solid $inputGray;
  color: $c-title;
  text-align: center;
  text-decoration: none;
}
.login-link.active{
  border-bottom: 3px solid $yellow;
}
.login-container{
  padding: 30px 30px 25px;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.login-label{
  width: 100%;
  align-items: baseline;
  position: relative;
  
  &.checkbox{
    display: flex;
    align-items: center;
    margin-top: 8px;
    .login-label-input{
      width: 20px;
      margin-left: 6px;
    }
  }
}


.login-input{
  width: 100%;
  background: $inputGray;
  padding: 18px 30px 18px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
  font-size: 1.8rem;
  color: $disabledFont;
}
.login-label.error .login-input{
  border: 1px solid $wrong_text;
}
.login-label.error .login-label-text{
  color: $wrong_text;
}
.login-label-text{
  font-size: 2rem;
  margin-bottom: 5px;
}
.login-label-input{
  position: relative;
}
.login-button{
  width: 100%;
  height: 60px;
  margin-top: 23px;
  background: $yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 2rem;
  font-weight: bold;
  &.recovery {
    font-weight: 400;
  }
}
.login-showPassword,
.login-showHint {
  position: absolute;
  height: 100%;
  width: 75px;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  z-index: 10;
}
.login-showHint {
  img {
    height: 27px;
  }
  &:hover {
    .login-hint {
      opacity: 1;
    }
  }
}
.login-hint {
  position: absolute;
  right: 0;
  transform: translate(105%, -75%);
  background: $c-hint;
  width: 350px;
  opacity: 1;
  transition: .3s;
  text-align: left;
  padding: 20px 30px 25px;

  font-size: 1.8rem;
  line-height: 2rem;


  &:before{
    content: '';
    border: 15px solid transparent;
    border-right: 15px solid $c-hint;
    display: block;
    width: 0;
    position: absolute;
    height: 0;
    left: 0px;
    transform: translate(-100%, 150%);
  }
}
.login-showPassword:focus{
  outline: none;
}
.session_error_message{
  border: 1px solid $wrong_text;
  background: #ffcdcd;
  color: $wrong_text;
  padding: 10px 20px;
  box-sizing: border-box;
}
.cw_link{
  font-size: 1.4rem;
  color: $c-title;
  text-decoration: none;
}
.session_error_message {
  position: absolute;
  top: 15px;
  width: 500px;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
}
.login-footer {
  display: flex;
  width: 90%;
  justify-content: space-between;
  position: absolute;
  bottom: -35px;
  color: gray;
}
.login-agency {
  position: absolute;
  bottom: 30px;
}
.login-footer{
  &-info {
    color: $disabledFont;
    &:hover {
      text-decoration: underline;
    }

  }
  &-recovery {
    color: $disabledFont;
    &:hover {
      text-decoration: underline;
    }
  }
}
.login-recovery{
  &-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 2rem;
  }
  &-subtitle {
    font-size: 2rem;
    margin-bottom: 5px;
  }
}
.unassigned {
  padding: 50px 35px 30px;
  font-size: 2rem;
}
.login-footer.centred {
  justify-content: center;
  bottom: -45px;
}
@media screen and (max-width: 1280px){
  .login-aside h1{
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px){
  .wrap.login{
    width: 90%;
    flex-direction: column;
  }
  .login-aside{
    width: 100%;
    min-height: auto;
    padding: 20px 40px;
  }
  .login-aside h1 {
    font-size: 2.5rem;
  }
  .login-aside footer{
    display: none;
  }
  .login-wrapper{
    width: 100%;
  }
  .login-label{
    display: flex;
    flex-direction: column;
  }
  .login-label-text{
    width: 100%;
  }
  .login-label-input{
    width: 100%;
  }

  .login-container p{
    margin: 0px;
  }
}