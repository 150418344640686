@import 'variables';

.tabs__container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.tabs__item{
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  justify-content: center;
  border-bottom: 2px solid $gray-back;
  box-sizing: border-box;
  padding: 10px 20px 10px;
  font-size: 1.6rem;
  font-weight: 700;
  color: $font-color;
  text-decoration: none;
}
.tabs__item.disabled{
  color: $font-sub;
}
.tabs__item.active{
  border-bottom: 4px solid $yellow;
}
.form-container{
  margin-top: 20px;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.form-label{
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}
.form-input{
  width: 100%;
  background: $gray-input;
  padding: 15px 20px 10px;
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
}
.form-label-text{
  width: 25%
}
.form-label-input{
  width: 80%;
  position: relative;
}
.form-button{
  width: 100%;
  height: 40px;
  background: $yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.form-button:hover{
  background: $dark-blue;
  color: $white;
  cursor: pointer;
}
.roles__container{
  width: 100%;
  display: flex;
  flex-direction: column;
  &-list {
    width: 100%;
    height: 700px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &-item {
      display: inline-block;
      width: calc(50% - 15px);
      margin-right: 15px;
      overflow: hidden;
      
      &:hover{
        color: $disabledFont
      }

    }
  }
}
.rolex__checkbox{
  margin-right: 10px;
}

.roles__row{
  display: flex;
  justify-content: space-between;


  &.header{
    width: 100%;
    justify-content: flex-start;

    padding-bottom: 4px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;

    input{
      margin-right: 8px;
    }
  }
}

.expected-item{
  background: $yellow;
  padding: 0px 10px;
  margin-left: 8px;
  border-radius: 5px;
  display: inline-block;
}