@import 'variables';

.breadcrumbs{
  font-size: 1.6rem;
  display: flex;
  line-height: 1;
  align-items: flex-end;
  margin: 20px 0;
  font-weight: 400;
  span {
    display: flex;
  }
}
.breadcrumbs__item{
  text-decoration: none;
  color: $disabledFont;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}
.breadcrumbs__item:hover{
  color: $disabledFontHov;
  text-decoration: underline;
}
.breadcrumbs__divider{
  margin-right: 10px;
  margin-left: 10px;
  color: $disabledFont;
}
