@import 'variables';

.confirm__popup{
  width: 100vw;
  height: 100vh;
  position: fixed;

  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

}

.confirm__blackwrap{
  width: 100vw;
  height: 100vh;

  background: $blackwrap;
  position: absolute;
  z-index: 101;

  top: 0px;
  left: 0px;
}

.confirm__window{
  width: 600px;

  box-sizing: border-box;
  padding: 30px 40px 40px;


  background: $white;
  position: relative;
  z-index: 102;

  border-top: 3px solid transparent;
}

.confirm__window.remove{
  border-top: 3px solid $decline;
}
.confirm__window.accept{
  border-top: 3px solid $accept;
}
.confirm__window.yellow{
  border-top: 3px solid $yellow;
}


.confirm__window p {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 10px 0 25px;
}

.confirm__message{
  width: 95%;
}

.confirm__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirm__title{
  font-size: 3rem;
  font-weight: 700;
}

.confirm__footer{
  display: flex;
}

.confirm__footer > .button{
  flex-grow: 1;
}
.confirm__close-icon{
  border: none;
  opacity: 0.8;

  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm__close-icon:hover{
  opacity: 1;
  cursor: pointer;
}

.confirm__textarea{
  width: 98%;
  background: $gray-back;
  color: $font-color;
  border: none;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 20px 20px;
  margin-bottom: 10px;

  font-size: 1.6rem;
}

.confirm__error{
  box-sizing: border-box;
  padding: 5px 20px;
  margin-bottom: 15px;
}