@import 'reset';
@import 'fonts';
@import 'variables';
@import 'common';

html{
    font-size: 10px;
    font-family: 'FiraSansCondensed', sans-serif;
}


