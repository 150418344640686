@import 'variables';

.sidebar {
  height: 100vh;
  width: 240px;
  min-height: 470px;
  position: fixed;
  float: left;
  padding: 35px 35px;
  background: $c-sidebar;
  &-header {
    height: 75px;
  }
  .login-logo {
    margin-bottom: 60px;
  }
  &-item {
    display: flex;
    position: relative;
    height: 20px;
    font-size: 1.5rem;
    color: $disabledFont;
    line-height: 20px;
    margin-bottom: 17px;
    &:hover {
      color: $disabledFontHov;
    }
    img {
      margin-right: 10px;
    }
  }
  &-btn {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    font-weight: inherit;
    font-size: 1.6rem;
    border-top: 1px solid lightgray;
    padding: 17px;
    width: 80%;
    .icon {
      &:hover {
        cursor: pointer;
      }
    }
    .icon {
      display: inline-block;
      line-height: 1;
      position: relative;
      height: 20px;
      width: 20px;
      img {
        height: 100%;
      }
    }
  }
}