* {
  box-sizing: border-box;
}

input {
  outline: none;
}

li {
  // list-style-type: none;
}

a {
  text-decoration: none;
  &:hover {
    cursor: pointer
  }
}

button {
  display: block;
  background: none;
  position: relative;
  border: none;
  outline: none;
  cursor: pointer;
}

img, a {
  user-select: none;
}

img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none
}
