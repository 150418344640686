@import 'variables';

.input-error{
  border: 1px solid $link-hover;
}

.required {
  color: $link-hover;
}

.text-red {
  text-align: center;
  color: $link-hover;
}

.loading {
  background: $disabledBack;
  color: $disabledFont;
}

.required{
  color: $decline;
}

.page-header{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.page-title{
  font-size: 3rem;
  font-weight: 700;
  margin: 0px;
}
.white-space{
  white-space: pre-wrap;
}
.text-divider{
  margin-right: 3px;
  margin-left: 3px;
}
.text-red{
  color: $decline;
}
.text-yellow{
  color: $yellow;
}
.text-bold{
  font-weight: 500;
  color: gray;
}
.text-orange{
  color: #ffa500
}
.text-green{
  color: $success-bcg;
}
.text-gray{
  color: gray;
}
.text-link{
  color: $links;
  &:hover{
    color: $link-hover;
    text-decoration: underline;
  }
}
.text-sub{
  color: $font-sub;
}
.text-noapp{
  color: $font-noapp;
}
.text-disabled{
  color: lightgray;
  cursor: default !important;
}
.button{
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin-right: 10px;

  font-size: 1.4rem;
  text-decoration: none;

  transition: 0.3s;
}
.button-full-width{
  width: 100%;
}

.button:hover{
  color: $dark-blue;
  background: $yellow;
  cursor: pointer;
}
.button-gray{
  background: $gray-input;
  color: $font-color;
}

.button-yellow{
  background: $yellow;
  color: $dark-blue;
  font-weight: 500;
  font-size: 1.6rem;
  padding: 7px 40px;
}
.button-yellow:hover{
  background: $links;
  color: $white;
}

.button-accept{
  background: $accept;
  color: $white;
}
.text-right {
  text-align: right;
}
.button-decline{
  background: $decline;
  color: $white;
}
.button:disabled{
  background: $gray-back;
  color: $dark-gray;
  opacity: 1;
}
.button:disabled:hover{
  cursor: default;
}
.alert__message{
  font-size: 2rem;
  background: $yellow-hover;
  border: 1px solid $brown-hover;
  box-sizing: border-box;
  padding: 15px 20px 25px;
  margin-bottom: 20px;
}
.alert__message h2,
.alert__message p{
  margin: 0px;
}
.unactive{
  opacity: 0.3;
  display: flex;
  width: 100%;
}

.required{
  color: red;
}
.text{
  font-size: 1.6rem;
}
.valid{
  color: $accept;
}
.invalid{
  color: $decline;
}
.link{
  color: $links;

  &:hover{
    color: $link-hover;
  }
}
.additional_info{
  box-sizing: border-box;
  padding: 10px 20px;

  font-size: 1.6rem;
  border-radius:  10px;

  background: $yellow-hover;

  margin-bottom: 20px;
  margin-top: 10px;

}

.text-white-space{
  white-space: pre-wrap;
}

.nav_link{

  display: inline;
  color: $adminBlue;
  text-decoration: underline;
  background-color: unset;
  padding: 0;
  height: auto;

  &:hover{
    color: $link-hover;
  }
}


.deposit-sign-input{
  font-size: 20px;
}

.hidden{
  // visibility: hidden;
  // opacity: 0;
  width: 1px;
  height: 1px;
}