@import 'variables';

.ml{
    &-8{
        margin-left: 8px
    }
}

.ed_programs_additional{

    display: flex;
    list-style-image: none;

    &__item{
        display: inline;
    }

}

.ed-stats{
    position: relative;

    &__input{

        height: 35px;
        padding: 8px 20px;
        background: $c-lightgray;
        border: none;
        box-sizing: border-box;
    }

    &__header{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__filters{
        &Btn{

            display: flex;
            align-items: center;
            font-size: 1.8rem;
            color: $links;
            margin-bottom: 20px;

            .f_icon{
                fill: $links;
            }

            &:hover{
                color: $link-hover;
                .f_icon{
                    fill: $link-hover;
                }
            }
        }
        &Icon{
            width: 24px;
            margin-right: 5px;
        }
    }

    &__label{

        position: relative;

        display: flex;
        flex-direction: column;

        margin-bottom: 10px;
        font-size: 1.5rem;
    }

    &__title{
        color: $uiGray;
        font-size: 1.5rem;
        margin-bottom: 5px;
    }

    &__body{
        position: absolute;

        left: 0px;
        top: 25px;

        width: 450px;
        padding: 30px 20px 10px;
        box-sizing: border-box;

        border-radius: 5px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        background: $white;

        transition: 0.3s;
        transform: translateY(0px);
        opacity: 0;

        box-shadow: 4px 4px 15px 5px rgba(0,0,0,0.25);
        visibility: hidden;

        z-index: 999;
        &.show{
            transform: translateY(20px);
            opacity: 1;
            visibility: visible;
        }
    }


    &__autocomplete{


        &-body{

            width: 250px;
            box-sizing: border-box;
            padding: 10px 20px;

            position: absolute;
            right: -270px;
            top: 0px;

            background: $added;

            &:after{
                content: '';
                position: absolute;

                width: 15px;
                height: 15px;

                left: -7px;
                top: 40%;
                transform: rotate(45deg);
                background: $added;
            }
        }

    }

    &__circles-container{
        display: flex;
        // justify-content: space-between;
    }
    &__circle{
        width: 20px;
        height: 20px;
        background: $gray-input;

        border-radius:  50%;
        margin-right: 20px;


        &.red{
            background: $wrong;
        }
        &.yellow{
            background: $yellow;
        }
        &.green{
            background: $success;
        }
    }



    &__applied-filters{
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        font-size: 1.5rem;
    }

    &__applied-item{
        box-sizing: border-box;
        padding: 5px 20px;
        border-radius: 5px;

        background: $added;
        margin: 5px 5px;

        color: $brown-hover;

        & > span{
            color: $brown-hover!important;
        }
    }

    &__footer{
        display: flex;

        & > .custom-button:first-child{
            margin-right: 10px;
            margin-bottom: 0px;
        }
    }
}
