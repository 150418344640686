@import 'variables';


.direction{

    &__header{
        display: flex;
        justify-content: space-between;
    }

    &__footer{
        display: flex;
        &Item{
            width: 100%;
            margin-left: 10px;
        }
    }

    &__label{
        display: flex;
        flex-direction: column;
        font-size: 1.6rem;
        margin-bottom: 15px;

        &.row{
            flex-direction: row;
        }
    }

    &__column{
        display: flex;
        flex-direction: column;
    }

    &__message{
        font-size: 1.6rem;
    }

    &__btn{

        font-weight: 600;
        border-radius: 10px;
        text-align: center;
        color: #333;
        margin-top: 5px;
        margin-bottom: 15px;
        background: $yellow;
        font-size: 1.8rem;
        padding: 10px 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover{
            background: $ucf;
        }

        &.finish{
            background: $success;
            color: $white;

            &:hover{
                background: $ucf;
            }
        }

        &.gray{
            background: $disabledBack;
            color:  #333;
            &:hover{
                background: $ucf;
            }
        }


        &.disabled{
            background: $disabledBack;
            color: $disabledFont;
            &:hover{
                color: $disabledFont;
                background: $disabledBack;
            }
        }
        
    }

    &__signer{
        margin-top: 10px;

        &-file{
            width: 100%;
            margin-right: 0px;
            margin-top: 10px;  
        }
    }

    &__search{
        width: 100%;
        background: #f6f6f6;
        padding: 8px 30px 8px;
        border-radius: 10px;
        box-sizing: border-box;
        border: 1px solid transparent;
        position: relative;
        font-size: 1.8rem;
        color: #878787;

        margin-bottom: 16px;
    }

    &__container{
        width: 100%;
        display: flex;

        &-inner{
            flex-direction: column-reverse;
        }
    }

    &__block{
        width: 100%;
        margin-right: 10px;

        &.users{
            // min-width: 300px;
            // max-width: 350px;

            .table__row{
                padding: 5px 10px;
                height: auto;
            }
            .table_cell.medium{
                padding: 0px 8px 0 0;
            }
        }

        &.selected{
            background: $added;
            padding: 10px 20px 30px;
            height: max-content;
            margin-right: 0px;

            .table__row{
                padding: 5px 10px;
                height: auto;
            }
            .table_cell.medium{
                padding: 0px 8px 0 0;
            }
        }
    }

    &__subtitle{
        font-size: 2rem
    }

    &__row{
        display: flex;
    }

    &__wrapper{
        margin-right: 16px;
        min-width: 50%;
    }

    &__error{
        font-size: 16px;
        color: $wrong_text
    }

    &__checkbox{
        margin-right: 10px;
    }

    &__comment{
        display: flex;
        font-size: 16px;
        margin-top: 16px;

        & .application__label:first-child{
            margin-right: 10px;
        }
        & .direction__btn:first-child{
            margin-right: 10px;
        }
    }


    &__label{
        display: flex;
        font-size: 16px;
        margin-top: 16px;
    }
}

.d-single{

    display: flex;
    flex-direction: column;

    padding: 10px 10px 25px;
    border: 1px solid transparent;

    &:hover{
        background: $yellow-hover;
        border: 1px solid $brown-hover;
    }

    &.bold{
        font-weight: 800;
        padding: 10px 10px;
        &:hover{
            background: transparent;
            border: 1px solid transparent;
        }
    }

    &__body{
        margin-top: 20px;
        font-size: 16px;
    }

    &__top{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__bottom{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        margin-top: 6px;
    }

    &__item{
        padding: 0px 16px;
        // outline: 1px solid red;
        display: flex;

        &.id{
            width: 50px;
        }
        &.spec_id{
            width: 200px;
        }
        &.large{
            width: 400px;
        }
        &.actions{
            width: 300px;
        }
        &.meduim{
            width: 250px;
        }
        &.full{
            width: 64%;
            min-width: 1000px;            
        }
        &.file{
            width: 200px;
        }

        &.description{
            font-size: 14px;
            color: $font-sub;
            display: inline;

            b{
                margin-right: 5px;
                // color: $font-color;
            }
        }
    }

    &__label{
        display: flex;
        align-items: center;
        margin-right: 24px;

        input{
            margin-left: 4px;
        }
    }

}