@import 'variables';

.negotiation__block{
    display: flex;
    flex-direction: column;

    padding-left: 15px;
    border-left: 3px solid $uiGray;

    margin-bottom: 20px;

    &Header{
        display: flex;
        justify-content: flex-start;
        align-items: baseline;

        h3{
            padding: 0px;
            margin: 0px;
            margin-left: 10px;
        }
    }
    
    &Message{
        margin: 10px 0 0 0;
        white-space: pre-wrap;
        word-break: break-word;
    }
}

.negotiation{

    &__header{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    &__data{
        display: flex;
    }

    &__files{
        width: 30%;
        padding-right: 10px;
        box-sizing: border-box;
    }

    &__fields{
        width: 70%;
    }


}


.project-app-wrapper{
    display: flex;
}
.project-app-grand-wrap {
    width: 100%;
    margin-top: 30px;

    &.scaned{
        width: 35%;
        margin-left: 20px;
    }
}


.file__wrapper{
    width: 100%;
    display: flex; 
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}
.file__remove{
    background: $decline;
    color: $white;

    padding: 16px 20px;
    box-sizing: border-box;
    margin-left: 10px;
    font-size: 1.6rem;

    &:hover{
        opacity: 0.8;
    }
}

.uploaded_link{
    font-size: 1.6rem;
    margin-left: 10px;
    // position: absolute;
    // right: -15px;
    // top: 15px;
}

.project-app-grand {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 15px;
    position: relative;


    &-wrap{
        &.uploaded{
            
            width: 60%;


            .uploaded_link{
                font-size: 2rem;
                margin: 0px;
            }


        }
    }   

    &-additional-upload{
        width: 100%;
        display: flex;
        flex-direction: row;

        align-items: center;

        .project-app-grand-button{
            margin-top: 0px!important;
            margin-left: 10px;
        }
        .project-app-grand-file{
            border-radius: 5px;
        }
    }

    &-title,
    &-file {
        // width: 50%;
    }
    &-title {
        font-size: 2.5rem;
        font-weight: 500;
        margin-bottom: 5px;
    }
    &-file {
        background-color: #E6E6E6;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 400px;
        font-size: 1.6rem;
        font-weight: 400;
        cursor: pointer;
        &:hover {
            background-color: #eee;
        }

        &-wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    &-link {
        width: 100%;
        border: 2px solid #29ABE2;
        padding: 10px;
        box-sizing: border-box;

        color: #29ABE2;
        font-size: 1.6rem;
        font-weight: 500;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    &-button {
        width: 50%;
        background-color: #FFCC2D;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-radius: 10px;
        width: 400px;
        font-size: 1.6rem;
        font-weight: 400;
        cursor: pointer;

        &.disabled{
            background: $disabledBack;
            color: $disabledFont
        }
    }
}

.rep_desc{
    margin: 10px 0;
}

.direction__files{
    display: flex;
    margin-bottom: 20px;
}

.direction__item{
    width: 50%;
    max-width: 400px;
    padding: 10px 20px;
    box-sizing: border-box;
    margin-right: 20px;

    background: $trueGray;
    border-radius: 10px;

    display: flex;
    align-items: center;

    color: $links;

    &:hover{
        color: $link-hover;
    }
}

.direction__icon{
    width: 50px;
    margin-right: 10px;
}

.direction__budget{

}   
.direction__application{

}


.reporting_file_icon{
    width: 45px;
    margin-right: 10px;
}


.uploader{

    &__message{
        margin: 10px 0px;
    }

    &__label{
        width: 100%;
        height: 200px;

        display: flex;
        justify-content: center;
        align-items: center;
        background: $gray-back;

        transition: 0.3s;

        &::before{
            content: 'Оберіть файл';
            color: $c-text
        }

        &:hover{
            cursor: pointer;
            opacity: 0.8;
            &::before{
                color: $link-hover
            }
        }

        &.uploading{
            height: 100px;

            &::before{
                content: '';
            }
        }
    }
}


.popup{

    width: 100vw;
    height: 100vh;
    position: fixed;

    top: 0px;
    left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    &__blackwrap{
        width: 100vw;
        height: 100vh;
    
        background: $blackwrap;
        position: absolute;
        z-index: 101;
    
        top: 0px;
        left: 0px;
    }

    &__body{
        width: 600px;

        box-sizing: border-box;
        padding: 30px 40px 40px;
    
    
        background: $white;
        position: relative;
        z-index: 102;

        
        border-radius: 5px;
        border-top: 3px solid transparent;
    }
}

.row__button{
    width: 100%;
    height: 60px;
    padding: 15px 20px 15px;
    box-sizing: border-box;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}
.row__button:hover{
    background: $yellow-hover;
    border-top: 1px solid $brown-hover;
    border-bottom: 1px solid $brown-hover;
    cursor: pointer;
}


.negotiation__actions{
    display: flex;
}
.negotiation__header-aside{
    width: 70%;
    display: flex;
    align-items: center;
}

.negotiation__header-aside .application__documents-protocol{
    font-size: 2rem;
}

.negotiation__button{
    height: 60px;
    width: 40%;
    margin-right: 10px;
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 1.8rem;
    font-weight: 600;
    background: #ffcc2d;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: black;

    &.wide{
        width: 40%;
    }

    &:hover{
        background: $lblue;
    }

    &.disabled{
        background: #dadada;
        color: #878787;
    }

    &.b-link{
        display: flex;
        align-items: center;
        justify-content: center;

        background: $lblue;

        color: $font-color;
        text-align: center;
        

        &:hover{
            background: $links;
            color: $white;
        }
    }
}


.reporting_statuses{
    display: flex;

}


.fileUploader{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ededed;
    transition: 0.3s;

    font-size: 2rem;
}

.reproting__manager{
    &__title{
        display: flex;
    }
    &__message{
        display: flex;
        flex-direction: column;
        font-size: 1.6rem;

        b{
            font-size: 1.6rem;
        }
        p{
            white-space: pre-wrap;
        }

        .link{
            display: inline-block;
            font-size: 1.6rem;
            margin-right: 8px;
        }
    }
}

.error_area{
    padding: 10px 20px;
    font-size: 20px;
    border: 1px solid $error-bcg;
    color: $error-bcg;
    margin-bottom: 10px;

    transition: 0.3s;
}


.act_wrapper{
    display: flex;
    flex-direction: column;
    
}

.documents{
    font-size: 16px;

    &__select{
        width: 100%;
        padding: 10px 20px;
        font-size: 20px;
        background: #eee;
        border: none

    }

    &__loader{
        font-size: 20px;
        padding-top: 16px;
        display: flex;
        justify-content: center; 
    }

    &__aditional{
        margin-top: 20px;

    }
}
