@import 'variables';

.lots-register {
  justify-content: space-between;
  &:hover {
    background: unset;
    border-bottom: 1px solid #c6c6c6;
    border-top: 1px solid #c6c6c6;
    cursor: default;
  }
  &-cell {
    cursor: default;
    &.last {
      width: unset !important;
    }
  }
}
.button-reg {
  display: flex;
  align-items: center;
  width: 250px;
  padding: 10px 29px;
  border-radius: 5px;
}
.button-reg-disabled {
  background-color: $button-disabled;
  color: gray;
}
.button-reg-active {
  background-color: $yellow;
  color: black;
}