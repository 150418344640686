@import 'variables';

.stars{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 50px;

	&__container{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__current_estimate{
		font-size: 3.5rem;
		font-weight: 600;
		margin-left: 20px;
	}

	&__item{
		display: flex;
		flex-direction: column;
		opacity: 0.7;
		cursor: pointer;



		&.active{
			opacity: 1;
			&.disabled{
				opacity: 1;
				&:hover{
					opacity: 1;
				}
			}
		}

		&:hover{
			cursor: pointer;
			opacity: 1
		}

		&.disabled{
			filter: grayscale(1);
			opacity: 0.5;
			img {
				cursor: no-drop;
			}
			&:hover{
				opacity: 0.7;
			}
		}
		
	}

	&__group{
		display: flex;
		align-items: center;
	}
	&__message{
		display: flex;
		align-items: center;
		font-size: 1.8rem;
		white-space: nowrap;
		margin-left: 15px;
		font-weight: 800;
		
		&.disabled{
			filter: grayscale(1);
		}
	}


}

.hidden-star{
	display: none;
}