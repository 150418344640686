@import 'variables';


.rollbacks{

    font-size: 1.6rem;

    &__list{
        list-style: none;
        padding: 0px;
    }

    &__checkbox{
        margin-right: 8px;
    }
    
    &__status{
        margin-left: 8px;
    }

    &__applications{
        display: flex;
    }

    &__selectedApps{
        min-width: 250px;
        background: #f9f5e4;
        padding: 10px 0px 30px;
        height: max-content;
        margin-right: 0px;
        margin-left: 32px;

        &.wide{
            width: 600px;
        }
    }

    &__app{
        &Item{
            display: flex;
            justify-content: space-between;
            padding: 2px 20px;

            &:hover{
                background: rgba(0, 0, 0, 0.15);
            }
        }
        &Button{
            color: $link-hover;
            border: none;
            text-decoration: underline;
            &:hover{
                color: $adminBlue
            }
        }
    }
}