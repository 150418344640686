@import 'variables';

.documents {

    &__page{
        font-size: 16px;

        .singleExpert__container{
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
        }
    }

    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        position: sticky;
        top: 0px;
        z-index: 999;
        background: $white;

        padding: 0 0px 0 16px;

        border-bottom: 1px solid $font-sub;
        margin-bottom: 20px;

        &.vertical{
            align-items: flex-start;
            flex-direction: column;

            .documents__row{
                width: 100%;
            }
            h2{
                padding: 0px;
                margin: 0px;
            }
        }
    }

    &__title{
        padding: 0;
        margin: 0;
        margin-bottom: 8px;
    }

    &__btn{
        font-size: 20px;
        background: #eee;
        padding: 8px 16px;
        border-radius: 5px;
        color: $font-color;

        &:hover{
            background: $yellow;
        }
    }

    &__wrapper{
        display: flex;
        font-size: 15px;
        margin-top: 20px;
    }

    &__editable{
        width: 70%;
        max-width: 760px;
        padding: 10px 20px 15px;

        &.fin{
            width: 85%;
            max-width: 900px;
        }
    }

    &__editor{
        width: 100%;
        background: #f9f9f9;
    }

    &__vairables{
        width: 50%;
        padding: 0 20px;

        &.fin{
            width: 35%;
        }
    }

    &__finFields{
        display: flex;
        flex-direction: column;
    }

    &__row{

        font-size: 15px;
        display: flex;
        padding: 5px 0;

        &--vertacal{
            flex-direction: column;
            padding-bottom: 20px;
            border-bottom: 1px solid #414141;
            margin-bottom: 20px;

            .documents__variablesInput{
                width: 100%;
                margin-bottom: 8px;
                max-width: 100%;
            }
        }

        &--variable{
            justify-content: space-between;
            padding: 8px 0 12px;
            border-bottom: 1px solid $font-sub;
        }
    }

    &__cell{
        display: flex;
        margin-right: 10px;
        &--vertical{
            flex-direction: column;
        }

        &--name{
            min-width: 140px;
        }
    }

    &__variablesInput{
        padding: 5px 10px;
        margin-right: 5px;
        max-width: 200px;
    }

    &__cta{
        background: $yellow;
        padding: 8px 0px;

        &:hover{
            background: $orange;
        }

    }

    &__label{
        margin-bottom: 16px;
    }
    &__labelText{
        padding: 0px;
        margin: 0px 0px 8px 0;
        font-size: 15px;
        color: $font-sub;

        & > b{
            color: $c-text
        }
    }

    &__removeVariable{
        color: $wrong_text;
        opacity: 0.7;
        &:hover{
            color: $link-hover;
            opacity: 1;
        }
    }


    &__store{
        background: $yellow;
        padding: 8px 32px;
        font-size: 16px;

        &:hover{
            background: $orange;
        }
    }

    &__delete{
        background: #d78d8d;
        color: #fff;
        padding: 8px 8px;
        font-size: 16px;

        &:hover{
            background: #ae0d0d;
        }
    }

    &__actions{
        display: flex;
        justify-content: space-between;

        .documents__delete{
            margin-right: 8px;
        }
    }

    &__error{
        background: $wrong;
        font-size: 16px;
        padding: 10px 20px;
        margin-bottom: 10px
    }

    &__warn{
        background: $added;
        font-size: 16px;
        padding: 10px 20px;
        margin-bottom: 10px
    }


    &__listItem{
        margin-bottom: 6px;

        b{
            margin-right: 6px;
        }
    }

    &__link{
        color: $adminBlue;

        &:hover{
            color: $link-hover
        }
    }

    &__form{
        max-width: 800px;

        &-finDetails{
            width: 48%;
            max-width: unset;
            margin-right: 1%;
        }
    }

    &__input{
        width: 100%;
        padding: 8px 20px;
        background: #f0f0f0;
        border: none;
        margin: 5px 0 15px;

    }

    &__deleteDoc{
        color: $wrong_text;
        padding: 10px 20px;
        margin-top: 5px;
        background: #e2e2e2;

        &:hover{
            color: $link-hover;
        }
    }


    &__deleteConfirm{
        height: 30px;
        margin-top: 5px;
        border-radius: 10px;
    }
    

    &__statusChanger{
        display: flex;
        font-size: 18px;

        select{
            width: 100%;
            padding: 5px 10px;
            background: #eee;
            border: none;
        }

        &-button{
            padding: 10px 20px;
            background: #ffcc00;
            margin-left: 12px;
        }
    }
}

.fin_details{
    margin-top: 30px;
    padding: 0 40px;
    border: 6px solid gray;

    &__content{
        position: relative;
        z-index: 1;
        padding: 20px 0;
    }

    p, h2{
        margin: 0px;
        margin: 0px;
    }

    &::after{
        content: '';
        position: absolute;
        top: 0px;
        left: 5%;
        display: block;
        width: 90%;
        height: 100%;
        background: #f9f9f9;
        z-index: 0;
    }

    &__type{
        display: flex;
        margin-top: 20px;
        
        label{
            margin-right: 10px;
            display: flex;
        }

        input{
            margin-right: 4px;
        }
    }

    &__validationContainer{
        width: 100%;
        display: flex;

        padding: 0px 20px;
        margin-left: 10px;

        .custom-button.small{
            width: 20%;
        }

        .custom-button.red{
            background: transparent;
            border: 2px solid $decline;
            color: $decline;
            margin-right: 10px;
            // height: 50px;

            &:hover{
                background: $decline;
                color: $c-text;
            }

            &.disabled {
                color: $disabledFont !important;
                background: $disabledBack !important;
                border: 2px solid $disabledFont;
            }
        }

        .custom-button.green{
            background: transparent;
            border: 2px solid $accept;
            color: $accept;
            margin-right: 10px;
            &:hover{
                background: $accept;
                color: $c-text;
            }

            &.disabled {
                color: $disabledFont !important;
                background: $disabledBack !important;
                border: 2px solid $disabledFont;
            }
        }
    }

    &__status{
        padding: 10px 20px;
        font-size: 16px;
    }
}

.ce-divider{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	margin: multipleEight(1) 0px;
	padding-left: 20px;
	border-left: 3px solid #eee;
    font-size: 12px;

    &__icon + &__icon{
		margin-left: multipleEight(6)
    }

    .ce-tool-head{
		margin: 0px
    }

	&__container{
		display: flex;
		justify-content: center;
		align-items: center;
    }

    &__item{
		padding: 3px 10px;
    }

    &--s{
		height: 25px;
    }

	&--m{
		height: 40px;
    }

	&--b{
		height: 80px;
    }

    &__line{
        width: 100%;
        margin-left: 16px;
        border-bottom: 2px solid #EEE;
    }
}


.fin__popup{
    height: 100%;
    top: 0px;
    right: 0px;
    position: fixed;
    display: flex;
    box-shadow: -10px 10px 30px 0px rgba(0,0,0,0.2);
    z-index: 1000;

    &.hide{
        display: none;
    }

    &Backwrap{
        position: fixed;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }


    &Body{
        background: $white;
        padding: 10px 20px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        position: relative;
        z-index: 1000;

        min-width: 400px;
        max-width: 400px;
    }

    .expForm__item{
        display: flex;
        flex-direction: column;

        .expForm__label{
            margin-bottom: 10px;
        }
    }

}	

	




	

	

