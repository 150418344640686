
$success: #60af9e;

$wrong: #fc9997;
$wrong_text: #d16053;
$ucf: #a3d2d9;

$white: #fff;
$inputGray: #f6f6f6;
$trueGray: #eee;

$link-hover: #fc0000;

$added: #f9f5e4;
$yellow: #ffcc2d;
$orange: #f7b20e;

$focus: #e6007e;

$lblue: #c9eafd;
$backblue: #51c0f7;
$adminBlue: #3b96d2;

$uiGray: #c6c6c5;
$disabledBack: #dadada;
$disabledFont: #878787;
$disabledFontHov: lightgray;

$gruntsBack: #f7f7f7;

$c-text: rgba(0,0,0,0.65);
$c-title: rgba(0,0,0,0.85);
$c-lightgray: #F2F2F2;
$c-hint: #FFFAE6;
$c-sidebar: #F7F9FA;

$white: #fff;
$font-color: #1d1d1b;
$font-sub: #b2b2b2;
$font-noapp: #878787;

$gray-back: #ededed;
$gray-input: #ededed;
$dark-gray: #c6c6c6;


$links: #36a9e1;

$yellow-hover: #fffae6;
$light-brown: #cbbba0;
$brown-hover: #b17f4a;

$dark-blue: #09245b;
$accept: #95C11F;
$decline: #ce5151;

$button-disabled: #B3B3B3;
$blackwrap: rgba(0, 0, 0, 0.5);

// messages
$success-bcg: #54b19e;
$success-bd: #4a8d7e;

$warning-bcg: #fdfae5;
$warning-bd: #76553a;

$error-bcg: #cf5051;
$error-bd: #8f323c;

// experts
$apply-btn: #3EB29D;
$dismiss-btn: #CE5151;

$link-btn: #29ABE2;